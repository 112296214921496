import React from 'react'
import {BrowserRouter, generatePath, Navigate, Route, Routes} from 'react-router-dom'
import App from './App'
import {NO_GUILD_CONTEXT, ROUTES} from './constants/routing'
import SecuredApp from './templates/SecuredAppTemplate/SecuredApp'
import WelcomePage from './home/WelcomePage'
import ItemLists from './itemList/ItemLists'
import ItemDetailsPage from './item/itemDetails/itemDetailsPage/ItemDetailsPage'
import GuildCreation from './guild/guildCreation/GuildCreation'
import GuildAdminRoute from './guild/guildManagement/GuildAdminRoute'
import GuildMembers from './guild/guildManagement/members/GuildMembers'
import GuildDetails from './guild/guildManagement/details/GuildDetails'

import GuildServiceRequests from './guild/guildServiceRequests/GuildServiceRequests'

import GuildManagementStructure from './guild/guildManagement/GuildManagementStructure'
import {ItemListDetail} from './itemList/itemListDetail/ItemListDetail'
import ServicesCataloguePage from './service/servicesCataloguePage/ServicesCataloguePage'
import TermsOfUse from './legal/legalsPage/TermsOfUse'
import TermsOfSales from './legal/legalsPage/TermsOfSales'
import LegalNotices from './legal/legalsPage/LegalNotices'
import PrivacyPolicy from './legal/legalsPage/PrivacyPolicy'
import CookiePolicy from './legal/legalsPage/CookiePolicy'
import LegalPageStructure from './legal/LegalPageStructure'
import UserServiceRequests from './service/serviceRequests/userServiceRequests/UserServiceRequests'

import AutoSignInPage from './authentication/SignInPage'
import AutoSignUpPage from './authentication/SingUpPage'
import HeaderApp from './templates/HeaderApp'
import GuildSFTPAccess from './guild/guildSFTPAccess/GuildSFTPAccess'
import AccessToInformationPackage from './service/serviceRequests/accessToInformationPackage/AccessToInformationPackage'
import OrganizationDetailsPage from './organization/organizationDetails/organizationDetailsPage/OrganizationDetailsPage'
import MonitorInformationPackageForm from './service/serviceRequests/monitoredBundle/MonitorBundleForm'
import UserServiceSubscriptions from './service/serviceSubscriptions/userServiceSubscriptions/UserServiceSubscriptions'
import ImportList from './itemList/ImportList/ImportList'
import SearchResultPageSelection from './search/searchResultPage/SearchResultPageSelection'
import ManageServiceSubscription from './service/serviceSubscriptions/common/ManageServiceSubscription'
import GuildServiceSubscriptions
    from './service/serviceSubscriptions/guildServiceSubscriptions/GuildServiceSubscriptions'
import PartMatchingSelectionStep
    from './service/serviceRequests/partMatching/Forms/ServiceSelectionStep/PartMatchingSelectionStep'
import GuildSharing from './guild/itemSharing/guildSharing/GuildSharing'
import ManageItemSharing from './guild/itemSharing/toOtherGuild/ManageItemSharing'
import PartExtractionServiceChoice from './service/serviceRequests/partExtraction/PartExtractionServiceChoice'
import DownloadServiceFileLink from './service/serviceRequests/DownloadServiceFile/DownloadServiceFileLink'
import {
    OneTimeExtractionRequest
} from './service/serviceRequests/partExtraction/Forms/ExtractionFormOptions/OneTimeExtractionRequest'
import {
    RecurringExtractionRequest
} from './service/serviceRequests/partExtraction/Forms/ExtractionFormOptions/RecurringExtractionRequest/RecurringExtractionRequest'
import {useCurrentGuildContext, useDatxPathGenerator} from './util/routing'
import LoadingSpinner from './genericComponents/LoadingSpinner'
import {useGetUserQuery} from './user/rtkUserApi'
import {useIsAuthenticated} from '@azure/msal-react'
import {LocalStorage} from './util/localStorage'
import AddSharingItems from './guild/itemSharing/toOtherGuild/AddSharingItems'
import ManagePrivateAttributeSharing from './guild/definitionSharing/ManagePrivateAttributeSharing'
import NotFoundPage from './notFound/NotFoundPage'
import {GuildAccessCheck} from './templates/SecuredAppTemplate/GuildAccessCheck'
import ManageSharedOrganisationItems from './guild/itemSharing/toOtherGuild/ManageSharedOrganisationItems'
import ManageSpecificItemSharing from './guild/itemSharing/toOtherGuild/SpecificItem/ManageSpecificItemSharing'
import SelectItemListForSharing from './guild/itemSharing/toOtherGuild/SpecificItem/SelectItemListForSharing'
import MaintenancePage from './maintenance/MaintenancePage'
import WorkflowConfigurationManagement from './guild/workflowConfiguration/WorkflowConfigurationManagement'
import CADModelCreationForm from './service/serviceRequests/cadModelCreation/CADModelCreationForm'
import {
    SelectPrivateAttributeRequestType
} from './service/serviceRequests/privateAttributeManagementV2/SelectPrivateAttributeRequestType'
import {
    SelectPrivateAttributeCreationType
} from './service/serviceRequests/privateAttributeManagementV2/privateAttributeCreation/SelectPrivateAttributeCreationType'
import {
    PrivateAttributeStringCreation
} from './service/serviceRequests/privateAttributeManagementV2/privateAttributeCreation/privateAttributeStringCreation/PrivateAttributeStringCreation'
import {
    PrivateAttributeDocumentCreation
} from './service/serviceRequests/privateAttributeManagementV2/privateAttributeCreation/privateAttributeDocumentCreation/PrivateAttributeDocumentCreation'
import {
    PrivateAttributeUpdate
} from './service/serviceRequests/privateAttributeManagementV2/privateAttributeUpdate/PrivateAttributeUpdate'
import {SelectNewItemRequestType} from './service/serviceRequests/newItems/SelectNewItemRequestType'
import {NewCompanyItemsForm} from './service/serviceRequests/newItems/newCompanyItems/NewCompanyItemsForm'
import {
    NewManufacturerItemsForm
} from './service/serviceRequests/newItems/newManufacturerItems/NewManufacturerItemsForm'
import {PartMatchingPaidForm} from './service/serviceRequests/partMatching/Forms/UploadForm/PartMatchingPaidForm'
import {
    PartMatchingSelfServiceForm
} from './service/serviceRequests/partMatching/Forms/UploadForm/PartMatchingSelfServiceForm'
import CreditManagement from './guild/guildManagement/credit/CreditManagement'
import {DisplaySetConfigurationPage} from './user/displaySetConfiguration/DisplaySetConfigurationPage'
import EditWorkflowConfiguration from './guild/workflowConfiguration/edit/EditWorkflowConfiguration'
import CreateWorkflowConfiguration from './guild/workflowConfiguration/create/CreateWorkflowConfiguration'

const DatxRoutes = () => {
    return (
        <App>
            <Routes>
                <Route path={ROUTES.login.path} element={<AutoSignInPage/>}/>
                <Route path={ROUTES.signup.path} element={<AutoSignUpPage/>}/>
                <Route path={ROUTES.maintenance.publicPath} element={<MaintenancePage/>}/>
                <Route element={<HeaderApp/>}>
                    <Route element={<SecuredApp/>}>
                        <Route element={<GuildAccessCheck/>}>
                            <Route path={ROUTES.root.path} element={<RootElement/>}/>
                            <Route path={ROUTES.welcome.path} element={<WelcomePage/>}/>
                            <Route path={ROUTES.extractionDownload.path} element={<DownloadServiceFileLink/>}/>
                            <Route path={ROUTES.manageItemLists.path} element={<ItemLists/>}/>
                            <Route path={ROUTES.importItemLists.path} element={<ImportList/>}/>
                            <Route path={ROUTES.detailItemList.path} element={<ItemListDetail/>}/>
                            <Route path={ROUTES.search.path} element={<SearchResultPageSelection/>}/>
                            <Route
                                path={ROUTES.searchByClassificationNodeDescendants.path}
                                element={<SearchResultPageSelection/>}
                            />
                            <Route path={ROUTES.detailItem.path} element={<ItemDetailsPage/>}/>
                            <Route path={ROUTES.organizationDetails.path} element={<OrganizationDetailsPage/>}/>
                            <Route path={ROUTES.guildCreation.path} element={<GuildCreation/>}/>
                            <Route path={ROUTES.servicesCatalogue.path} element={<ServicesCataloguePage/>}/>

                            <Route
                                path={ROUTES.partMatchingServiceChoice.path}
                                element={<PartMatchingSelectionStep/>}
                            />
                            <Route path={ROUTES.partMatchingServicePaidForm.path} element={<PartMatchingPaidForm/>}/>
                            <Route
                                path={ROUTES.partMatchingServiceSelfServiceForm.path}
                                element={<PartMatchingSelfServiceForm/>}
                            />
                            <Route
                                path={ROUTES.accessToInformationPackageServiceForm.path}
                                element={<AccessToInformationPackage/>}
                            />
                            <Route
                                path={ROUTES.partExtractionServiceChoice.path}
                                element={<PartExtractionServiceChoice/>}
                            />
                            <Route
                                path={ROUTES.oneTimeExtractionServiceForm.path}
                                element={<OneTimeExtractionRequest/>}
                            />
                            <Route
                                path={ROUTES.recurringExtractionServiceForm.path}
                                element={<RecurringExtractionRequest/>}
                            />
                            <Route
                                path={ROUTES.informationPackageMonitoring.path}
                                element={<MonitorInformationPackageForm/>}
                            />
                            <Route path={ROUTES.cadModelCreation.path} element={<CADModelCreationForm/>}/>

                            <Route path={ROUTES.newPartsServiceForm.path} element={<SelectNewItemRequestType/>}/>
                            <Route
                                path={ROUTES.newManufacturerPartsServiceForm.path}
                                element={<NewManufacturerItemsForm/>}
                            />
                            <Route path={ROUTES.newCompanyPartsServiceForm.path} element={<NewCompanyItemsForm/>}/>

                            <Route
                                path={ROUTES.managePrivateAttribute.path}
                                element={<SelectPrivateAttributeRequestType/>}
                            />
                            <Route
                                path={ROUTES.selectTypeCreationPrivateAttribute.path}
                                element={<SelectPrivateAttributeCreationType/>}
                            />
                            <Route
                                path={ROUTES.createStringAttribute.path}
                                element={<PrivateAttributeStringCreation/>}
                            />
                            <Route
                                path={ROUTES.updateStringAttribute.path}
                                element={<PrivateAttributeUpdate/>}
                            />
                            <Route
                                path={ROUTES.createDocumentAttribute.path}
                                element={<PrivateAttributeDocumentCreation/>}
                            />

                            <Route path={ROUTES.userServiceRequests.path} element={<UserServiceRequests/>}/>
                            <Route
                                path={ROUTES.userServiceSubscriptions.path}
                                element={<Redirect to={ROUTES.userActiveServiceSubscriptions.path ?? ''}/>}
                            />
                            <Route
                                path={ROUTES.userActiveServiceSubscriptions.path}
                                element={<UserServiceSubscriptions activeSubscription={true}/>}
                            />
                            <Route
                                path={ROUTES.userInactiveServiceSubscriptions.path}
                                element={<UserServiceSubscriptions activeSubscription={false}/>}
                            />
                            <Route
                                path={ROUTES.manageUserServiceSubscriptions.path}
                                element={<ManageServiceSubscription/>}
                            />
                            <Route
                                path={ROUTES.displaySetConfiguration.path}
                                element={<DisplaySetConfigurationPage/>}
                            />
                            <Route path={ROUTES.any.path} element={<NotFoundPage/>}/>
                            <Route path="*" element={<NotFoundPage/>}/>

                            <Route element={<GuildAdminRoute/>}>
                                <Route path={ROUTES.guildManagement.path} element={<GuildManagementStructure/>}>
                                    <Route path="" element={<Redirect to={ROUTES.guildDetails.path ?? ''}/>}/>
                                    <Route path={ROUTES.guildDetails.path} element={<GuildDetails/>}/>
                                    <Route path={ROUTES.guildCredit.path} element={<CreditManagement/>}/>
                                    <Route path={ROUTES.manageGuildUser.path} element={<GuildMembers/>}/>
                                    <Route path={ROUTES.serviceRequests.path} element={<GuildServiceRequests/>}/>
                                    <Route
                                        path={ROUTES.guildServiceSubscriptions.path}
                                        element={<Redirect to={ROUTES.guildActiveServiceSubscriptions.path ?? ''}/>}
                                    />
                                    <Route
                                        path={ROUTES.guildActiveServiceSubscriptions.path}
                                        element={<GuildServiceSubscriptions activeSubscription={true}/>}
                                    />
                                    <Route
                                        path={ROUTES.guildInactiveServiceSubscriptions.path}
                                        element={<GuildServiceSubscriptions activeSubscription={false}/>}
                                    />
                                    <Route
                                        path={ROUTES.manageServiceSubscription.path}
                                        element={<ManageServiceSubscription/>}
                                    />
                                    <Route
                                        path={ROUTES.itemsSharedTo.path}
                                        element={<Redirect to={ROUTES.itemsSharedToOtherGuilds.path ?? ''}/>}
                                    />
                                    <Route
                                        path={ROUTES.itemsSharedToOtherGuilds.path}
                                        element={<GuildSharing toMyGuild={false}/>}
                                    />
                                    <Route
                                        path={ROUTES.manageOrganisationSharedItems.path}
                                        element={<ManageSharedOrganisationItems/>}
                                    />
                                    <Route
                                        path={ROUTES.manageSpecificSharedItems.path}
                                        element={<ManageSpecificItemSharing/>}
                                    />
                                    <Route
                                        path={ROUTES.selectItemListForSharing.path}
                                        element={<SelectItemListForSharing/>}
                                    />
                                    <Route
                                        path={ROUTES.itemsSharedToMyGuild.path}
                                        element={<GuildSharing toMyGuild={true}/>}
                                    />
                                    <Route path={ROUTES.itemsShareToOtherGuild.path} element={<ManageItemSharing/>}/>
                                    <Route path={ROUTES.addSharingItems.path} element={<AddSharingItems/>}/>
                                    <Route
                                        path={ROUTES.addSharingPrivateAttributes.path}
                                        element={<ManagePrivateAttributeSharing/>}
                                    />
                                    <Route
                                        path={ROUTES.workflowConfiguration.path}
                                        element={<WorkflowConfigurationManagement/>}
                                    />
                                    <Route
                                        path={ROUTES.createWorkflowConfiguration.path}
                                        element={<CreateWorkflowConfiguration />}
                                    />
                                    <Route
                                        path={ROUTES.editWorkflowConfiguration.path}
                                        element={<EditWorkflowConfiguration />}
                                    />
                                    <Route path={ROUTES.sftpAccess.path} element={<GuildSFTPAccess/>}/>
                                </Route>
                            </Route>
                        </Route>
                    </Route>

                    <Route path={ROUTES.legal.path} element={<LegalPageStructure/>}>
                        <Route path="" element={<Redirect to={ROUTES.termsOfUse.path ?? ''}/>}/>

                        <Route path={ROUTES.termsOfUse.path} element={<TermsOfUse/>}/>
                        <Route path={ROUTES.termsOfSales.path} element={<TermsOfSales/>}/>
                        <Route path={ROUTES.legalNotices.path} element={<LegalNotices/>}/>
                        <Route path={ROUTES.privacyPolicy.path} element={<PrivacyPolicy/>}/>
                        <Route path={ROUTES.cookiePolicy.path} element={<CookiePolicy/>}/>
                    </Route>

                    <Route path={ROUTES.legal.publicPath} element={<LegalPageStructure/>}>
                        <Route path="" element={<Redirect to={ROUTES.termsOfUse.publicPath ?? ''}/>}/>
                        <Route path={ROUTES.termsOfUse.publicPath} element={<TermsOfUse/>}/>
                        <Route path={ROUTES.termsOfSales.publicPath} element={<TermsOfSales/>}/>
                        <Route path={ROUTES.legalNotices.publicPath} element={<LegalNotices/>}/>
                        <Route path={ROUTES.privacyPolicy.publicPath} element={<PrivacyPolicy/>}/>
                        <Route path={ROUTES.cookiePolicy.publicPath} element={<CookiePolicy/>}/>
                    </Route>
                </Route>
            </Routes>
        </App>
    )
}

/**
 * Prevent redirection to a guild context page before the active guilds have been retrieved
 *
 * If no currentGuildContext is available the Root element will try to use the context stored in LocalStorage to initialize the app.
 */
const RootElement = () => {
    const authenticated = useIsAuthenticated()
    const {isFetching} = useGetUserQuery(undefined, {skip: !authenticated})
    const [currentGuildContext] = useCurrentGuildContext()
    const isInitialized = authenticated && !isFetching

    return isInitialized ? (
        <Navigate
            to={generatePath(ROUTES.welcome.path ?? '', {
                currentGuildId: currentGuildContext || LocalStorage.getGuildContext()?.guildId || NO_GUILD_CONTEXT
            })}
        />
    ) : (
        <LoadingSpinner/>
    )
}

const Redirect = ({to}: { to: string }) => {
    const generatePath = useDatxPathGenerator()

    return <Navigate replace to={generatePath(to)}/>
}

export default <BrowserRouter children={<DatxRoutes/>}/>
