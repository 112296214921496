import React, {useEffect, useMemo, useState} from 'react'
import {useFormik} from 'formik'
import {boolean, mixed, object, string} from 'yup'
import {
    Button,
    Checkbox,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Radio,
    Select,
    SelectChangeEvent,
    TextField
} from '@mui/material'
import {ROUTES} from '../../../constants/routing'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {getServiceName} from '../../../service/model/ServiceEnum'
import CustomSwitch from '../../../genericComponents/customSwitch/CustomSwitch'
import { WorkflowConfigurationStage} from '../model/WorkflowConfigurationStage'
import {IdType} from '../../../util/models/IdType'
import {WorkflowConfiguration} from '../model/WorkflowConfiguration'
import {workflowGuildId} from '../../../search/SearchParams'
import {LoadingButton} from '@mui/lab'
import {WarningAmberOutlined} from '@mui/icons-material'
import TextFilterInputInSelect from './dropdownFilter/TextFilterInputInSelect'
import {User} from '../../../user/model/User'
import {Label} from '../../../genericComponents/typographie/Label'
import {availableServices} from '../WorkflowConfigurationManagement'
import {Guild} from '../../models/Guild'
import {Member} from '../../models/Member'

const WorkflowConfigurationTemplate = ({
                                                  pageTitle, guild, isEdit, onSubmit, workflowDetails, isLoading
}: {
    pageTitle: string
    guild: Guild | undefined
    isEdit: boolean
    onSubmit: (formValues: WorkflowConfiguration) => void
    workflowDetails?: WorkflowConfiguration
    isLoading: boolean
}) => {

    const generatePath = useDatxPathGenerator()
    const navigate = useNavigate()
    const [currentGuildContext] = useCurrentGuildContext()

    const [selectedApproverIds, setSelectedApproverIds] = React.useState<IdType[]>([])
    const [searchText, setSearchText] = useState('')

    const guildAdmins = guild?.guildUsers.filter((user) => user.admin)
    const guildUsers = guild?.guildUsers ?? []
    const numberOfGuildAdmins = guildAdmins?.length
    const numberOfGuildMembers = guildUsers.length

    const workflowInitialValues: WorkflowConfiguration = {
        id: '',
        guildId: currentGuildContext?.guildId ?? '',
        service: '',
        isDefault: false,
        name: '',
        quotationAutoApproval: false,
        quotationAutoApprovalThreshold: null,
        serviceRequestAutoValidation: false,
        requestors: [],
        approvers: []
    }

    const formik = useFormik({
        initialValues: workflowDetails || workflowInitialValues,
        enableReinitialize: true,
        validationSchema: object({
            service: string().required(),
            name: string().required(),
            quotationAutoApproval: boolean(),
            quotationAutoApprovalThreshold: mixed() // could be '', null, number
                .when('quotationAutoApproval', ([isAutoApproval], schema) => {
                    if (isAutoApproval) return schema.nullable().defined('Quotation threshold is required')
                    return schema.nullable()
                })
        }),
        onSubmit: (values: WorkflowConfiguration) => onSubmit(values)
    })

    /* For Edit */
    useEffect(() => {
        if (workflowDetails) {
            setSelectedApproverIds(workflowDetails.approvers.map(approver => approver.id))
        }
    }, [workflowDetails])

    // when selected approvers changes, get User and update in formik
    useEffect(() => {
        formik.setFieldValue('approvers', getUsers(selectedApproverIds, guildAdmins ?? []))
    }, [selectedApproverIds, formik, guildAdmins])

    const requestors = Array.from(formik.values.requestors)
    const isDefaultWorkflow = formik.values.name === 'Default'

    const getUsers = (userIds: IdType[], users: Member[]) => {
        return users.filter(user => userIds.includes(user.user.id))
            .map(user => user.user)
    }

    /* For create */
    useMemo(() => {
        if (!isEdit && guildUsers) {
            // todo: to be remove in future story:
            // set default requestors values to All Requestors
            formik.setFieldValue('requestors', guildUsers?.map(user => user.user) ?? [])
        }
    }, [guild])

    const filteredGuildAdmins = useMemo(() => {
        const containsText = (text: string, searchText: string) =>
            text.toLowerCase().indexOf(searchText.toLowerCase()) > -1

        return guildAdmins?.filter((adminUser) => containsText(adminUser.user.email, searchText))
            .map(admin => admin.user)
    }, [searchText, guildAdmins])
    const numberOfFilteredGuildAdmins = filteredGuildAdmins?.length

    const formikApprovers = formik.values.approvers

    const handleApproverChange = (event: SelectChangeEvent<IdType[]>, child?: any) => {
        const { target: { value } } = event
        const selectedOptionValue = child?.props.value

        if (selectedOptionValue === undefined) return // skip when filter textfield is clicked

        const isSelectAllOptionSelected = selectedOptionValue === 'selectAll'
        const numberOfSelectedApprovers = formikApprovers.length

        if (isSelectAllOptionSelected) {
            if (numberOfSelectedApprovers !== numberOfFilteredGuildAdmins) {
                const allGuildAdminIds = filteredGuildAdmins?.map(admin => admin.id) ?? []
                setSelectedApproverIds(allGuildAdminIds)
            } else setSelectedApproverIds([])
        }
        else setSelectedApproverIds(value as string[])
    }

    const renderApproversSelect = (selectedApprover: IdType[]) => {
        const numberOfSelectedApprovers = selectedApprover.length

        if ((numberOfSelectedApprovers === numberOfGuildAdmins) || isDefaultWorkflow)
            return 'All Guild Admins'
        if (numberOfSelectedApprovers === 0)
            return <span className="placeholder">Select</span>

        return `${numberOfSelectedApprovers} Selected`
    }

    const shouldApproverOptionChecked = (option: User)=> {
        if (selectedApproverIds) return selectedApproverIds.find(id => id === option.id) !== undefined
        return false
    }

    const isAutoApprovalThresholdError = formik.values.quotationAutoApprovalThreshold !== null &&
        !formik.values.quotationAutoApprovalThreshold && formik.values.quotationAutoApproval === true

    const showErrorMessage = () => {
        if (!isAutoApprovalThresholdError) {
            return null
        }

        return <div className="threshold-error">
            <WarningAmberOutlined />
            <span> This field cannot be empty </span>
        </div>
    }

    return (
        <form onSubmit={formik.handleSubmit} className="workflow-configuration-container">
            <h1 className="workflow-configuration-title">{pageTitle}</h1>
            <div className="workflow-configuration-content">
                <div className="workflow-form-container">
                    <div className="workflow-service-container">
                        <Label
                            id="workflow-service-label"
                            className="workflow-label"
                            htmlFor="workflowServiceSelect"
                            required
                        >
                            Service
                        </Label>
                        <Select
                            data-testid="workflow-service-dropdown-selector"
                            id="workflow-service-dropdown-selector"
                            value={formik.values.service}
                            onChange={(event) => formik.setFieldValue('service', event?.target?.value)}
                            name="workflowServiceSelect"
                            displayEmpty
                            disabled={isDefaultWorkflow || isEdit}
                            renderValue={(service) =>
                                service !== '' ? (getServiceName(service)) :
                                    <span className="placeholder">Choose Service</span>
                            }
                        >
                            {availableServices?.length &&
                                availableServices.map((service) => (
                                    <MenuItem
                                        className="service-items"
                                        value={service}
                                        key={service}
                                        autoFocus={false}
                                        data-cy={`select-option-${getServiceName(service)}`}
                                    >
                                        {getServiceName(service)}
                                    </MenuItem>
                                ))}
                        </Select>
                    </div>
                    <div className="workflow-name-container">
                        <Label
                            id="workflow-name-label"
                            className="workflow-label"
                            htmlFor="workflowNameTextInput"
                            required
                        >
                            Name of Workflow
                        </Label>
                        <TextField
                            data-testid="workflow-name-text-input"
                            value={formik.values.name}
                            placeholder="Input Workflow Name"
                            disabled={isDefaultWorkflow || isEdit}
                            onChange={(e) => formik.setFieldValue('name', e?.target?.value)}
                        />
                    </div>
                    <div className="workflow-approvers-container">
                        <Label
                            id="workflow-request-approvers-label"
                            className="workflow-label"
                            htmlFor="workflowApproversSelect"
                            required
                        >
                            Approvers
                        </Label>
                        <Select
                            name="workflowApproversSelect"
                            labelId="workflow-approvers-select-label"
                            id="workflow-approvers-select"
                            multiple
                            value={selectedApproverIds}
                            onChange={handleApproverChange}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={renderApproversSelect}
                            MenuProps={{ autoFocus: false }}
                            displayEmpty
                            disabled={isDefaultWorkflow}
                        >
                            <div className="filter-input" role="group">
                                <TextFilterInputInSelect
                                    searchText={searchText}
                                    setSearchText={setSearchText}
                                    inputClassName="search-text-input"
                                    label="Search User"
                                ></TextFilterInputInSelect>
                            </div>
                            <MenuItem value="selectAll" className="approver-items">
                                <Checkbox checked={formikApprovers.length !== 0 &&
                                    formikApprovers.length === numberOfFilteredGuildAdmins}
                                />
                                <ListItemText className="menu-item-label" primary="(Select All)" />
                            </MenuItem>

                            {filteredGuildAdmins?.map((option) => (
                                <MenuItem key={option.id} value={option.id}
                                          className="approver-items"
                                          data-testid={`approver-${option.email}`}
                                >
                                    <Checkbox checked={shouldApproverOptionChecked(option)} />
                                    <ListItemText className="menu-item-label" primary={option.email} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="workflow-requestors-container">
                        <Label
                            id="workflow-requestors-label"
                            className="workflow-label"
                            htmlFor="workflowRequestorsSelect"
                            required
                        >
                            Requestors
                        </Label>
                        <div className="edit-value">
                            {isDefaultWorkflow || numberOfGuildMembers === requestors.length ?
                                'All Guild Members' : `${requestors.length} Guild Members`}
                        </div>
                    </div>
                    <div className="workflow-request-approval-container">
                        <div className="edit-value">Request Approval</div>
                        <div>
                            <CustomSwitch
                                disabled={false}
                                value={WorkflowConfigurationStage.AUTO_APPROVE_SERVICE_REQUEST}
                                isChecked={formik.values.serviceRequestAutoValidation}
                                toggleChecked={(e) => formik.setFieldValue('serviceRequestAutoValidation', e.target.checked)}
                                dataTestid="auto-request-approval-toggle"
                            />
                        </div>
                    </div>
                    <div className="workflow-quotation-approval-container">
                        <div className="edit-value">Quotation Approval</div>
                        <div>
                            <CustomSwitch
                                disabled={false}
                                value={WorkflowConfigurationStage.AUTO_APPROVE_QUOTATION}
                                isChecked={formik.values.quotationAutoApproval}
                                toggleChecked={(e) => formik.setFieldValue('quotationAutoApproval', e.target.checked)}
                                dataTestid="auto-quotation-approval-toggle"
                            />
                            <div>
                                <div className="quotation-options">
                                    <div className="radio-group-1">
                                        <Radio
                                            className="radio-button"
                                            checked={formik.values.quotationAutoApprovalThreshold === null}
                                            onChange={() => formik.setFieldValue('quotationAutoApprovalThreshold', null)}
                                            disabled={!formik.values.quotationAutoApproval}
                                        />
                                        <span className="edit-title">Always</span>
                                    </div>
                                    <div className="radio-group-2">
                                        <Radio
                                            className="radio-button"
                                            data-testid="quotation-input"
                                            checked={formik.values.quotationAutoApprovalThreshold !== null}
                                            onChange={() => formik.setFieldValue('quotationAutoApprovalThreshold', '')}
                                            disabled={!formik.values.quotationAutoApproval}
                                        />
                                        <div className="credits-box">
                                            <span className="edit-title">When quotation is lower than:</span>
                                            <TextField
                                                data-testid="quotation-text-field"
                                                size="small"
                                                autoComplete="off"
                                                className="quotation-threshold-input"
                                                label={formik.values.quotationAutoApprovalThreshold ? '' : 'Number of Credits'}
                                                InputProps={{
                                                    inputProps: {min: 0}
                                                }}
                                                InputLabelProps={{
                                                    className: 'custom-label',
                                                    shrink: false
                                                }}
                                                type="number"
                                                value={formik.values.quotationAutoApprovalThreshold || ''}
                                                onChange={(e) =>
                                                    formik.setFieldValue('quotationAutoApprovalThreshold', e.target.value)
                                                }
                                                error={isAutoApprovalThresholdError}
                                                helperText={showErrorMessage()}
                                                disabled={formik.values.quotationAutoApproval === false ||
                                                    formik.values.quotationAutoApprovalThreshold === null}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="workflow-configuration-form-footer">
                    <Button
                        className="workflow-configuration-form-previous"
                        variant="text"
                        type="button"
                        onClick={() => navigate(generatePath(ROUTES.workflowConfiguration.path))}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        type="submit"
                        className="workflow-configuration-form-next"
                        data-testid="workflow-configuration-save-button"
                        disabled={!formik.dirty || !formik.isValid}
                        loading={isLoading}
                    >
                        {isEdit ? 'Save Changes' : 'Add Workflow'}
                    </LoadingButton>
                </div>
            </div>
        </form>
    )
}

export default WorkflowConfigurationTemplate
