import WorkflowConfigurationTemplate from '../formTemplate/WorkflowConfigurationTemplate'
import {
    useGetGuildQuery, useGetWorkflowConfigurationQuery, useUpdateWorkflowConfigurationMutation
} from '../../rtkGuildApi'
import {IdType} from '../../../util/models/IdType'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {WorkflowConfiguration} from '../model/WorkflowConfiguration'
import {setSnackbarMessage} from '../../../genericComponents/commonSlice'
import {ROUTES} from '../../../constants/routing'
import {useTypedDispatch} from '../../../util/store'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'
import { useEffect } from 'react'
import { workflowGuildId } from '../../../search/SearchParams'

const EditWorkflowConfiguration = () => {
    const generatePath = useDatxPathGenerator()
    const navigate = useNavigate()
    const dispatch = useTypedDispatch()

    const [searchParams] = useSearchParams()
    const [currentGuildContext] = useCurrentGuildContext()
    const {data: guild} = useGetGuildQuery(currentGuildContext?.guildId, { skip: !currentGuildContext?.guildId })

    const getPageTitle = 'Edit Workflow'

    const { id } = useParams()
    const {data: workflowConfiguration, isLoading} = useGetWorkflowConfigurationQuery(id as IdType)
    const [updateWorkflowConfiguration, updateResult] = useUpdateWorkflowConfigurationMutation()

    useEffect(() => {
        if (currentGuildContext?.guildId && currentGuildContext.guildId !== searchParams.get(workflowGuildId)) {
            navigate(generatePath(ROUTES.workflowConfiguration.path))
        }
    }, [currentGuildContext?.guildId, navigate, searchParams, generatePath])

    const handleUpdateWorkflowConfiguration = (values: WorkflowConfiguration) => {
        updateWorkflowConfiguration({workflowConfiguration: values})
            .unwrap()
            .then(() => {
                dispatch(setSnackbarMessage('Workflow configuration has been saved'))
                navigate(generatePath(ROUTES.workflowConfiguration.path))
            })
    }

    if (isLoading) return <div className="spinner-container"><LoadingSpinner/></div>
    return <WorkflowConfigurationTemplate pageTitle={getPageTitle} guild={guild}
                                          isEdit={true} onSubmit={handleUpdateWorkflowConfiguration}
                                          workflowDetails={workflowConfiguration}
                                          isLoading={updateResult.isLoading}
    />
}

export default EditWorkflowConfiguration